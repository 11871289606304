import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_BATCH_LOAD_STATUS } from '../../connection.data';

@Injectable()
export class BatchLoadStatusService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_BATCH_LOAD_STATUS;
    }
}